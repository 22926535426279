import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Container } from '@mui/material';
import Sidebar from '../components/sidebar';
import Chat from '../components/chat';
import { useNavigate } from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


const MainPage = () => {
  const navigate = useNavigate()
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleSelectConversation = (conversation) => {
    console.log(conversation)
    setSelectedConversation(conversation)};

  const handleLogout = () => {
    // logout logic
    localStorage.setItem("token", "")
    navigate("/")
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar>
        <Sidebar onSelectConversation={handleSelectConversation} onLogout={handleLogout} />
          <Typography variant="h6">ZenithAI</Typography>
        </Toolbar>
      </AppBar>
      <Container>
        
        <Chat conversation={selectedConversation} onSelectConversation={handleSelectConversation}/>
      </Container>
    </ThemeProvider>
  );
};

export default MainPage;