import React, {useState} from 'react';
import Button from '@mui/material/Button';
import SignupForm from '../components/signupform';
import { GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

export default function Signup(props) {
	return(
		<div className="App">
		
		<h1>ZenithAI Sign Up</h1>
		<GoogleReCaptchaProvider reCaptchaKey="6LeTmeskAAAAAI_8UR2q8aypW44BRab_tvoapxWf">
		<SignupForm />
		</GoogleReCaptchaProvider>
	    
	    </div>
	);
}