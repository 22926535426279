import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Tooltip, Container } from '@mui/material';
import { Menu, Add } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import Typography from '@mui/material/Typography';


const emails = ['username@gmail.com', 'user02@gmail.com'];
function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;
  const [self, setSelf] = useState({})
  const [days_, setDays] = useState(0)
  const [minutes_, setMinutes] = useState(0)
  const [hours_, setHours] = useState(0)
  const handleClose = () => {
    onClose(selectedValue);
  };

  const timeFromNow = (time) =>{
    console.log(time)
    const remaining = time - Date.now();
    console.log(remaining)
    const days = Math.floor((remaining) / (24 * 60 * 60 * 1000));
    const hours = Math.floor((remaining) / (60 * 60 * 1000)) % 24;
    const minutes = Math.floor((remaining) / (60 * 1000)) % 60;
    setDays(days)
    setHours(hours)
    setMinutes(minutes)
    console.log(days)
    return {days, minutes, hours}
  }
  const handleListItemClick = (value) => {
    onClose(value);
  };
  const fetchSelf = async ()=>{
    var token = localStorage.getItem('token')
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    const res = await axios.get(process.env.REACT_APP_BACKEND+'/web/self', config);
    setSelf(res.data)
    timeFromNow(res.data.expiration)
    console.log(res.data)
  }
  useEffect(() => {
      fetchSelf()
      
    }, []);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Account</DialogTitle>
      <List sx={{ pt: 0 }}>
        
          <ListItem disableGutters>
            <ListItemButton key={self.email}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                  {self.picture === null ? <><PersonIcon /></>:<><img src={self.picture} style={{width: '100%'}}/></>}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={self.email} />
            </ListItemButton>
          </ListItem>
          <Container>
        {self.pro ? <>
        <Typography>Your account is pro tier. You have unlimited messages on the Webapp and WhatsApp, and access to our SMS service. You also have access to our GPT-4 powered system for higher quality responses, our newer features, and exclusive cutting edge features.</Typography>
        <Typography>To cancel your current plan, email support@zenithai.io with the subject "CANCEL" from the email associated with your account</Typography>
        </>: self.premium? <>
        <Typography>Your account is premium tier. You have unlimited messages on the Webapp and WhatsApp, and access to our SMS service. You will also have access to some of our newer features.</Typography>
        <Typography>Please allow 48 hours for your old subscription to be cancelled upon upgrading. Also ensure that the email you sign up with is the one for this account.</Typography>
        <Tooltip title="An exceptional plan powered by GPT-4. Has no limits on GPT-4. Unlock exclusive cutting edge features.">
          <Button href="https://buy.stripe.com/aEUbIP64P8VugW4bIW">Upgrade to Pro Tier</Button>
        </Tooltip>
        <Typography>To cancel your current plan, email support@zenithai.io with the subject "CANCEL" from the email associated with your account</Typography>
        </>: self.customer_id !== null && self.customer_id !== undefined? <>
        <Typography>Your account is on the basic tier. You have a limit of 25 messages per day on the webapp and 15 per day on WhatsApp.</Typography>
        <Typography>Please allow 48 hours for your old subscription to be cancelled upon upgrading. Also ensure that the email you sign up with is the one for this account.</Typography>
        <Tooltip title="An unlimited plan to break through your limits. Has access to GPT-4 (limit 70 messages per week).">
          <Button href="https://buy.stripe.com/8wM7sz2SDdbK0X6bIQ">Upgrade to Premium</Button>
        </Tooltip>
        <Tooltip title="An exceptional plan powered by GPT-4. Has no limits on GPT-4. Unlock exclusive cutting edge features.">
          <Button href="https://buy.stripe.com/aEUbIP64P8VugW4bIW">Upgrade to Pro Tier</Button>
        </Tooltip>
        <Typography>To cancel your current plan, email support@zenithai.io with the subject "CANCEL" from the email associated with your account</Typography>
        </>: <>
        <Typography>Your account is a free trial. You have a limit of 25 messages per day on the webapp and 15 per day on WhatsApp. Your free trial will expire in {days_} days, {hours_} hours, and {minutes_} minutes.</Typography>
        <Typography>Please allow 48 hours for your old subscription to be cancelled upon upgrading. Also ensure that the email you sign up with is the one for this account.</Typography>
        <Button href="https://buy.stripe.com/28o9AH8cXgnW8pyaEO">Upgrade to Paid Plan</Button>
        <Tooltip title="An unlimited plan to break through your limits. Has access to GPT-4 (limit 70 messages per week).">
          <Button href="https://buy.stripe.com/8wM7sz2SDdbK0X6bIQ">Upgrade to Premium</Button>
        </Tooltip>
        <Tooltip title="An exceptional plan powered by GPT-4. Has no limits on GPT-4. Unlock exclusive cutting edge features.">
          <Button href="https://buy.stripe.com/aEUbIP64P8VugW4bIW">Upgrade to Pro Tier</Button>
        </Tooltip>
        
        </>}
        </Container>
        
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};


const Sidebar = ({ onSelectConversation, onLogout }) => {
  const navigate = useNavigate()
  const [conversations, setConversations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [account, setAccount] = useState(false)

  const handleToggle = () => setIsOpen(!isOpen);
  const onAccount = ()=> setAccount(!account);

  const fetchConversations = async () => {
    var token = localStorage.getItem("token")
    var token = localStorage.getItem('token')
    if(token === "" || token === null || token === undefined){
      navigate("/")
    }
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    const res = await axios.get(process.env.REACT_APP_BACKEND+'/web/conversations', config);
    setConversations(res.data);
    console.log(res.data)
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  return (
    <>
      <IconButton onClick={handleToggle}>
        <Menu />
      </IconButton>
      <Drawer open={isOpen} onClose={handleToggle}>
        <List height='100%' style={{maxHeight: '100%', overflow: 'auto'}}>
        <ListItem button onClick={() => {onSelectConversation(null)
      setIsOpen(!isOpen)}}>
            
            <ListItemText primary={"New Conversation"} />
            <Add />
          </ListItem>
          {conversations.map((conversation) => (
            <ListItem button key={conversation.id} onClick={() => {onSelectConversation(conversation)
          setIsOpen(!isOpen)}}>
              <ListItemText primary={conversation.name} />
            </ListItem>
          ))}
        </List>
        <List style={{ marginTop: `auto` }}>
          <ListItem button onClick={onAccount}>
              <ListItemText primary={'Account'} />
            </ListItem>
          <ListItem button onClick={onLogout}>
            <ListItemText primary={'Sign Out'} />
          </ListItem>
        </List>
      </Drawer>
      <SimpleDialog
        selectedValue={account}
        open={account}
        onClose={onAccount}
      />
    </>
  );
};

export default Sidebar;