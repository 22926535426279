import React, {useState} from 'react';
import Button from '@mui/material/Button';
import VerifyPhoneForm from '../components/verifyphoneform';

export default function VerifyPhone(props) {
	return(
		<div className="App">
		<h1>Verify your Phone number</h1>
		
		<VerifyPhoneForm />
	    
	    </div>
	);
}