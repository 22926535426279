import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, TextField, IconButton, Switch, Select, MenuItem } from '@mui/material';
import {Send} from '@mui/icons-material';
import axios from 'axios';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LightMode from '@mui/icons-material/LightMode';
import Flight from '@mui/icons-material/Flight';
import Warning from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Item2 =  styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  '&:hover': {
     transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    backgroundImage: 'linear-gradient(rgb(34 83 138), rgba(255, 255, 255, 0.05))',
    backgroundColor: 'rgb(58 114 180 / 0%)',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const Chat = ({ conversation, onSelectConversation }) => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const [tz, setTz] = useState("")
  var [self, setSelf] = useState({})
  var [gpt4, setGPT4] = useState(false);
  var [prompts, setPrompts] = useState([])
  var [temp, setTemp] = useState("None Selected")

  const handleGPT4 = (event) => {
    setGPT4(event.target.checked);
  };

  const getTz = ()=>{
    setTz(new Date()
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'short',
        })
        .slice(4))
    console.log(
      new Date()
        .toLocaleDateString('en-US', {
          day: '2-digit',
          timeZoneName: 'short',
        })
        .slice(4),
    );
  }
  const handleTextChange = (e) => setText(e.target.value);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // prevent default enter behavior
      handleMessageSend();
    }
  };
  //get user name and pic, display
  const handleMessageSend = async () => {
    var res;
    if(loader === true){
      return;
    }
    setLoader(true)
    setMessages(oldMessages => [...oldMessages, {speaker:"User",text: text}])
    var token = localStorage.getItem('token')
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    if(conversation !== null){
      if(gpt4 === true && (self.pro === true || self.premium == true)){
        console.log('gpt-4')
        res = await axios.post(process.env.REACT_APP_BACKEND+`/web/send-message-gpt4`, { message: text, conversation_id: conversation.id }, config);
      } else {
        res = await axios.post(process.env.REACT_APP_BACKEND+`/web/send-message`, { message: text, conversation_id: conversation.id }, config);
      }
    } else {
      if(gpt4 === true && (self.pro === true || self.premium == true)){
        console.log('gpt-4')
        res = await axios.post(process.env.REACT_APP_BACKEND+`/web/send-message-gpt4`, { message: text}, config);
      } else {
        res = await axios.post(process.env.REACT_APP_BACKEND+`/web/send-message`, { message: text}, config);
      }
      
      //setconversation 
      onSelectConversation(res.data.convo)
    }
    var received = {text: res.data.text, speaker: "Assistant"}
    setMessages(oldMessages => [...oldMessages, received]);
    setLoader(false)
    console.log(res.data)
    setText('');
  };
  const handlePrompt = (event) => {
    var x;
    for(var i = 0; i< prompts.length; i++){
      if(prompts[i].act === event.target.value){
        x = prompts[i].prompt
      }
    }
    if(event.target.value === "None Selected"){
      x = ""
    }
    setText(x);
    setTemp(event.target.value);
  };
  const fetchMessages = async () => {
    var token = localStorage.getItem('token')
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    const res = await axios.get(process.env.REACT_APP_BACKEND+`/web/conversations/${conversation.id}`, config);
    setMessages(res.data);
    console.log(res.data)
  };
  const fetchSelf = async ()=>{
    var token = localStorage.getItem('token')
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    const res = await axios.get(process.env.REACT_APP_BACKEND+'/web/self', config);
    setSelf(res.data)
    console.log(res.data)
  }
  const fetchPrompts = async ()=>{
    var token = localStorage.getItem('token')
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    const res = await axios.get(process.env.REACT_APP_BACKEND+'/web/prompts', config);
    setPrompts(res.data)
    console.log(res.data)
  }
  useEffect(() => {
    var token = localStorage.getItem('token')
    const decodedJwt = parseJwt(token)
    getTz()
    fetchSelf()
    fetchPrompts()
    console.log(decodedJwt)
    console.log(decodedJwt.exp *1000 - Date.now())
    if(decodedJwt.exp * 1000 < Date.now())
    {
      navigate('/')
      localStorage.setItem('token', "")
      navigate("/")
    }
    if (conversation) {
      fetchMessages();
    }
  }, [conversation]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {conversation ? (
        <>
        {self.pro === true || self.premium == true?
              <>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5">Model switch - left for GPT-3.5-turbo, right for GPT-4</Typography>
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
              <Switch
                checked={gpt4}
                onChange={handleGPT4}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              </Box>

              {gpt4 === true ?<>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">GPT-4 enabled</Typography><br />
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography>Enable GPT-4 for more informative, more concise, more reasoned, and much more intelligent answers, but slower. GPT-4 scored 710 on SAT math.</Typography>
              </Box>
              </>:<>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">GPT-3.5-turbo enabled</Typography><br />
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography>Enable GPT-3.5 for faster, but less informative answers.</Typography>
              </Box>
              </>}
              </>:
              <></>
            }
        <Box flexGrow={1} p={2} overflow="auto">
        <h4>Prompt Templates</h4>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Prompt Suggestions"
          onChange={handlePrompt}
          value={temp}
          
        >
          <MenuItem value={'None Selected'}>None Selected</MenuItem>
          {prompts.map((prompt)=>{
            return <MenuItem value={prompt.act}>{prompt.act}</MenuItem>
          })}
        </Select>
          <h4>{conversation.name}</h4>
          {messages.map((message) => (
            <Paper key={message.id} variant="outlined" square>
              <Typography align={'left'} style={{whiteSpace: "pre-line"}}>{message.speaker === "Assistant" ? "ZenithAI" : "You"}: {message.text}</Typography>
            </Paper>
          ))}
        </Box></>
      ) : (
        <>
          <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3">ZenithAI {self.premium === true ? "Premium" : self.pro === true? "Pro": ""}</Typography>

            
          </Box>
          <Typography variant="h6">{self.premium === true ? "unlimited gpt-3.5, 70 gpt-4 messages per week" : self.pro === true ? "unlimited access to gpt-3.5 and gpt-4": ""}</Typography>
          {self.pro === true || self.premium === true?
              <>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
              <Typography variant="h5">Model switch - left for GPT-3.5-turbo, right for GPT-4</Typography>
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
              <Switch
                checked={gpt4}
                onChange={handleGPT4}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              </Box>

              {gpt4 === true ?<>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">GPT-4 enabled</Typography><br />
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography>Enable GPT-4 for more informative, more concise, more reasoned, and much more intelligent answers, but slower. GPT-4 scored 710 on SAT math.</Typography>
              </Box>
              </>:<>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5">GPT-3.5-turbo enabled</Typography><br />
              </Box>
              <Box flexGrow={1} p={2} display="flex" justifyContent="center" alignItems="center">
                <Typography>Enable GPT-3.5 for faster, but less informative answers.</Typography>
              </Box>
              </>}
              </>:
              <></>
            }
            <h4>Prompt Templates</h4>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Prompt Suggestions"
              onChange={handlePrompt}
              value={temp}
              
            >
              <MenuItem value={'None Selected'}>None Selected</MenuItem>
              {prompts.map((prompt)=>{
                return <MenuItem value={prompt.act}>{prompt.act}</MenuItem>
              })}
            </Select>
        <Box sx={{ flexGrow: 1, pb: 5, pt:10}}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{display: {xs: 'none', md: 'block'}}} md={4}>
                <Typography variant="h5">Examples </Typography>
                <LightMode/>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                <Typography variant="h5">Abilities</Typography>
                <Flight/>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                <Typography variant="h5">Issues</Typography>
                <Warning/>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item2 button onClick={()=>setText("Who won the 2022 Nobel Prize in Physics?")}>Who won the 2022 Nobel Prize in Physics?</Item2>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>Allows users to provide follow-up corrections</Item>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>Not particularly good at math (fixes coming soon)</Item>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item2 button onClick={()=>setText("How can the Pomodoro method increase my efficiency?")}>How can the Pomodoro method increase my efficiency?</Item2>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>Has longterm memory of previous interactions</Item>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>May occasionally generate incorrect information</Item>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item2 button onClick={()=>setText("Give me a full itinerary for me waking up at 8 am, working out at 5pm, going to sleep at 11 pm, and eating at restaurants in New York City.")}>Give me a full itinerary for me waking up at 8 am, working out at 5pm, going to sleep at 11 pm, and eating at restaurants in New York City. </Item2>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>Can search the web for up-to-date information</Item>
                </Grid>
                <Grid item sx={{display: {xs: 'none', md: 'block'}}} xs={6} md={4}>
                  <Item button>May occasionally produce harmful instructions or biased content</Item>
                </Grid>
              </Grid>
            </Box>
        </>
      )}
      {loader === true ? <>
      <CircularProgress />
      </>:<></>}
      
        <Box p={1} display="flex">
          <TextField value={text} multiline onKeyDown={handleKeyDown} onChange={handleTextChange} fullWidth variant="outlined" label="Message" />
          <IconButton onClick={handleMessageSend}>
            <Send />
          </IconButton>
        </Box>
        <p>Your timezone (for scheduling purposes): {tz}</p>
        
      
    </Box>
  );
};

export default Chat;