import React, { useState, useRef, useCallback, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('homepage');
    // Do whatever you want with the token
    console.log(token)
    var valid = await axios.post(process.env.REACT_APP_BACKEND+'/web/is_user_human', {token: token})
    console.log(valid.data.isHuman)
    //have to send to backend. backend gets score. once score is reasonably high, should allow user to sign up
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/signup', { email: email, password: password, name: name });
      console.log(response.data);
      localStorage.setItem('token',response.data.token);
      navigate("/verify-phone");

    } catch (err) {
      console.error(err.response.data);
    }
  };
    const handleSigninGoogle = async (response: GoogleLoginResponse) => {
    const tokenId  = response.credential;
    console.log(tokenId)
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/google-signup', { token: tokenId});
      console.log(response.data);
      localStorage.setItem('token',response.data.token);
      navigate("/verify-phone")
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    
    <form onSubmit={handleSignup} className="form">
    <GoogleLogin
        onSuccess={credentialResponse => {
          console.log(credentialResponse);
          handleSigninGoogle(credentialResponse)
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    
        <label>
          <TextField variant="filled" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
        </label>
        <label>
          <TextField variant="filled" type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
        </label>
        <label>
          <TextField variant="filled" label="Name" value={name} onChange={(e) => setName(e.target.value)} required/>
        </label>
        
        <Button variant="contained" type="submit" color="primary" className="form__custom-button">Sign Up</Button>

      
    </form>
    
  );
};

export default SignupForm;