import logo from './logo.svg';
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signin from './pages/signin';
import Signup from './pages/signup';
import MainPage from './pages/main';
import VerifyPhone from './pages/verifyphone';
import "./styles.css";
import { GoogleOAuthProvider } from '@react-oauth/google';


function App() {
  
  return (
    <div className="App">
    <GoogleOAuthProvider clientId="237226293677-uo9cpul332ubb9no7tam6o88b0cn7n7h.apps.googleusercontent.com">
    
      <Router>
        <Routes>
          <Route path='/' element={<Signin/>} />
          <Route path='/signup' element={<Signup/>} />
          <Route path='/main' element={<MainPage/>} />
          <Route path='/verify-phone' element={<VerifyPhone/>} />
        </Routes>
      </Router>

      </GoogleOAuthProvider>
    </div>
  );
}

function Error(){
  return(
  <h1>Page Not Found</h1>
  )
}

function HomePage() {

  return <h1>Hello!</h1>;
 
}

export default App;
