import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";


const SigninForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('')
  const navigate = useNavigate()
  const handleSignin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/signin', { email: email, password: password});
      console.log(response.data);
      setToken(response.data.token)
      localStorage.setItem('token',response.data.token);
      navigate("/main");
    } catch (err) {
      console.error(err.response.data);
    }
  };
  const handleSigninGoogle = async (response: GoogleLoginResponse) => {
    const tokenId  = response.credential;
    console.log(tokenId)
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/google-signin', { token: tokenId});
      console.log(response.data);
      if(response.data.token === null || response.data.token === undefined){
        navigate('/signup')
      }
      setToken(response.data.token)
      localStorage.setItem('token', response.data.token);
      navigate("/main");
    } catch (err) {
      console.error(err.response.data);
      if(JSON.stringify(err.response.data) === JSON.stringify({error: "User not found"})){
        navigate('/signup')
      }
    }
  };

  return (
    <form onSubmit={handleSignin} className="form">
      <GoogleLogin
        onSuccess={credentialResponse => {
          console.log(credentialResponse);
          handleSigninGoogle(credentialResponse)
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
        <label>
          <TextField variant="filled" label="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
        </label>
        <label>
          <TextField variant="filled" type="password" label="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
        </label>
        <Button variant="contained" type="submit" color="primary" className="form__custom-button">Sign In</Button>

      
    </form>
  );
};

export default SigninForm;