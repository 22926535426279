import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from 'mui-tel-input'


const VerifyPhoneForm = () => {
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false)
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
  const verifyPhone = async ()=>{
    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/verify-phone-code', { code: code}, config);
      console.log(response.data);
      if(response.data.success === true){
        localStorage.setItem("referral_code", response.data.referral_code)
        navigate("/main");
      }
    } catch (err) {
      console.error(err.response.data);
    }
  }
  const handleSignin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const config = {
      headers:{
        'Authorization': `Bearer ${token}`
      }
    };
    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND+'/web/send-phone-code', { phone: phone}, config);
      console.log(response.data);
      if(response.data.success === true){
        setCodeSent(response.data.success)
      }
      //navigate("/main");
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <form onSubmit={handleSignin} className="form">
    
        <label>

        <MuiTelInput value={phone} placeholder="Enter phone number" defaultCountry="US" onChange={setPhone} required/>
        </label>
        <p>If you are located outside of the US, you will need to check WhatsApp</p>
        <Button variant="contained" type="submit" color="primary" className="form__custom-button">Send Code</Button>
        {codeSent ?
        <label>
          <TextField variant="filled" label="Enter 6 digit code (sent to your phone)" value={code} onChange={(e) => setCode(e.target.value)} required/>
          <Button variant="contained" color="primary" className="form__custom-button" onClick={verifyPhone}>Verify Phone</Button>
        </label>:<></>
        }
      
    </form>
  );
};

export default VerifyPhoneForm;